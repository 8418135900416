<template>
    <el-dialog
        :visible.sync="dialogVisible"
        class="LearnTeacherSuccess"
        :class="{ relationshipSuccess: relationship }"
        :show-close="true"
        @close="confirm"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <img
            :src="
                relationship
                    ? require('@/assets/stbs.png')
                    : require('@/assets/teachersuccess.png')
            "
            class="img"
            alt=""
        />
        <img src="@/assets/gif.gif" class="gif" v-if="relationship" alt="" />
        <div class="ab">
            <div class="text">{{ text }}</div>
            <div class="confirm click" @click="confirm">确认</div>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "LearnTeacherSuccess",
        props: {
            text: {
                type: String,
                default: "恭喜！您已拜师成功!",
            },
            type: {
                type: Boolean,
                default: true,
            },
            relationship: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                dialogVisible: true,
            };
        },

        methods: {
            confirm() {
                if (this.type) {
                    this.$https.post("/api/my_master/notice/cancel").then((res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        this.dialogVisible = false;
                    });
                } else {
                    this.$emit("close");
                }
            },
        },
    };
</script>

<style lang="less" scoped>
.LearnTeacherSuccess,
.relationshipSuccess {
    /deep/.el-dialog {
        margin-top: 0 !important;
        background: none;
        box-shadow: none;
        width: 942px;

        .el-dialog__header {
            padding: 0;
            .el-dialog__headerbtn {
                z-index: 99;
            }
        }
        .el-dialog__body {
            padding: 0;
            position: relative;
            .img {
                width: 942px;
            }
            .ab {
                position: absolute;
                left: 0;
                bottom: -95px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .text {
                    font-size: 26px;
                    font-weight: 400;
                    color: #ffffff;
                    margin-bottom: 47px;
                }
                .confirm {
                    width: 130px;
                    height: 42px;
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    border-radius: 21px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 24px;
                }
            }
        }
    }
}

.relationshipSuccess {
    /deep/.el-dialog {
        width: 755px;
        height: 713px;
        margin-top: 10vh !important;
        background: #fff;
        .el-dialog__body {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .img {
                width: 633px;
                height: 530px;
                z-index: 10;
            }

            .gif {
                position: absolute;
                z-index: 9;
                width: 537px;
                top: 0;
                left: 0;
                margin: auto;
                right: 0;
            }

            .ab {
                position: unset;
                z-index: 10;
                .text {
                    color: #000000;
                    // position: absolute;
                    // bottom: 66px;
                }
            }
        }
    }
}
</style>